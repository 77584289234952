import { InfoCard } from '@backstage/core-components';
import React from 'react';
import ReactJson from 'react-json-view';
import { ManifestProps } from '../AemManifestPage';

export const ManifestJSONCard = ({ manifestFile }: ManifestProps) => {
  return (
    <InfoCard title="Manifest JSON Data Viewer" key="test">
      <ReactJson collapsed={2} src={manifestFile ?? {}} />
    </InfoCard>
  );
};
