import DOMPurify from 'dompurify';

export function sanitizeHtml(html: string) {
  return DOMPurify.sanitize(html, {
    USE_PROFILES: { html: true },
    ADD_TAGS: ['google-codelab'],
    CUSTOM_ELEMENT_HANDLING: {
      tagNameCheck: /^google-codelab-/,
      allowCustomizedBuiltInElements: true, // customized built-ins are allowed
    },
  });
}

export function loadScripts(scriptUrls: string[]) {
  scriptUrls.forEach(url => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  });
}

export function loadStylesheets(stylesheetUrls: string[]) {
  stylesheetUrls.forEach(url => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  });
}

export function durationAsMinutes(duration: number): string {
  return duration < 60 ? `${duration} min` : `${Math.floor(duration / 60)} min`;
}
