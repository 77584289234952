import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import {
  Cluster,
  ClusterSchema,
  SecurityComplianceApi,
} from '@mercedes-benz/seccomp-dashboard-common';
import { ResponseError } from '@backstage/errors';

export const securityComplianceApiRef = createApiRef<SecurityComplianceApi>({
  id: 'plugin.seccomp.api',
});

export type SecurityComplainceRequest = {
  method: string;
  path: string;
  responseAsJson?: boolean;
};

export class SecurityComplianceApiClient implements SecurityComplianceApi {
  // constructor(private readonly catalogApi: CatalogApi);
  constructor(
    private readonly fetchApi?: FetchApi,
    private readonly discoveryApi?: DiscoveryApi,
  ) {}

  async getDashboardCompliance(): Promise<Cluster[]> {
    const clusters = await this.submitRequest({
      method: 'GET',
      path: '/cluster/metadata',
    });
    const unsafeClusters = clusters.clusters.flat();
    const result = ClusterSchema.array().safeParse(unsafeClusters);
    if (!result.success) {
      return unsafeClusters;
    }
    return result.data;
  }

  async submitRequest({
    path,
    method,
    responseAsJson = true,
  }: SecurityComplainceRequest): Promise<any> {
    const url = `${await this.discoveryApi?.getBaseUrl('seccomp-dashboard-permission')}${path}`;
    const headers: Record<string, string> = {};

    const response = await this.fetchApi?.fetch(url, { method, headers });

    if (!response?.ok) {
      throw await ResponseError.fromResponse(response!);
    }

    return responseAsJson ? response.json() : response;
  }
}
