import { createApiRef } from '@backstage/core-plugin-api';
import {
  Package,
  PackagesApi,
  PackageServiceType,
  PlatformPackagePriceType,
} from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const packagesApiRef = createApiRef<PackagesApi>({
  id: 'plugin.tdms-next.packages-api',
});

export class PackagesApiClient extends ApiClient implements PackagesApi {
  getPackages(): Promise<Package[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/packages`,
      },
      actionPermissions.tenantPermissions.read,
    );
  }

  addPackage(body: {
    name: string;
    description: string;
    provider: string;
  }): Promise<Package> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/packages`,
        body,
      },
      actionPermissions.backOfficePackages.create,
    );
  }

  updatePackage(
    packageId: number,
    body: { name: string; description: string; provider: string },
  ): Promise<Package> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/packages/${packageId}`,
        body,
      },
      actionPermissions.backOfficePackages.update,
    );
  }

  getBookingPackageServices(
    packageSlug: string,
  ): Promise<PackageServiceType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/packages/slug/${packageSlug}/services`,
      },
      actionPermissions.backOfficeServices.read,
    );
  }

  getBookingPackagePrices(
    packageId: number,
  ): Promise<PlatformPackagePriceType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/packages/${packageId}/price`,
      },
      actionPermissions.backOfficePackages.read,
    );
  }

  addPackagePrice(
    packageId: number,
    body: { year: number; monthlyPrice: number },
  ): Promise<PlatformPackagePriceType> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/packages/${packageId}/price`,
        body,
      },
      actionPermissions.backOfficePackages.update,
    );
  }

  updatePackagePrice(
    packageId: number,
    priceId: number,
    body: PlatformPackagePriceType,
  ): Promise<PlatformPackagePriceType> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/packages/${packageId}/price/${priceId}`,
        body,
      },
      actionPermissions.backOfficePackages.update,
    );
  }

  addServiceToBookingPackage(
    packageId: number,
    serviceId: number,
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/packages/${packageId}/service/${serviceId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficePackages.update,
    );
  }

  removeServiceFromBookingPackage(
    packageId: number,
    serviceId: number,
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/packages/${packageId}/service/${serviceId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficeServices.delete,
    );
  }
}
