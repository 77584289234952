import { Progress, WarningPanel } from '@backstage/core-components';
import { ManifestFile } from '@mercedes-benz/seamless-common';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { AEM_PLUGIN_ANNOTATION } from '../../annotation';
import { useSeamlessManifests } from '../../hooks/useSeamlessManifests';
import { ComponentConfigCard } from '../component-config-card/ComponentConfigCard';
import { ManifestJSONCard } from '../manifest-json-card/ManifestJSONCard';
import { ManifestResourcesCard } from '../manifest-resources-card/ManifestResourcesCard';
import { ManifestSummaryCard } from '../manifest-summary-card/ManifestSummaryCard';

export type ManifestProps = {
  aemPluginName: string;
};

export const Manifest = (props: ManifestProps) => {
  const { manifestFileList, error, loading, versionList } =
    useSeamlessManifests(props.aemPluginName);

  const [manifestFile, setManifestFile] = useState<ManifestFile>();
  const [version, setVersion] = useState<string>();

  const onChange = useCallback(
    (data: any) => {
      setVersion(data.value);
    },
    [setVersion],
  );

  useEffect(() => {
    const firstVersionInList = versionList?.length
      ? versionList[0].value
      : undefined;
    const forcedVersion = version ?? firstVersionInList;
    const foundManifestFile = manifestFileList?.find(
      manifestData => manifestData.version === forcedVersion,
    );
    setManifestFile(foundManifestFile);
  }, [version, manifestFileList, versionList, loading]);

  return loading ? (
    <Progress />
  ) : (
    <>
      {error ? (
        <WarningPanel
          title="Error"
          message="Unable to fetch the manifest configuration data."
          severity="error"
          defaultExpanded
        />
      ) : (
        !manifestFile && (
          <WarningPanel
            title="AEM Manifest not found"
            message={`Unable to find an AEM manifest for '${props.aemPluginName}' component. Please update the value of '${AEM_PLUGIN_ANNOTATION}' annotation with a valid AEM component name.`}
            severity="info"
            defaultExpanded
          />
        )
      )}
      <Grid container spacing={3} alignItems="stretch">
        {manifestFile && (
          <>
            <Grid item xs={3} className="grid-item">
              <Select options={versionList} onChange={onChange} />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <ManifestSummaryCard manifestFile={manifestFile} />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <ManifestResourcesCard manifestFile={manifestFile} />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <ComponentConfigCard manifestFile={manifestFile} />
            </Grid>
            <Grid item xs={12} className="grid-item">
              <ManifestJSONCard manifestFile={manifestFile} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
