import { createApiRef } from '@backstage/core-plugin-api';
import { TdmsClient } from '@mb.io/tdms-common';
import { Mixin as mixin } from 'ts-mixer';
import { BookingsApiClient } from './Bookings';
import { EntitiesApiClient } from './Entities';
import { PackagesApiClient } from './Packages';
import { ReportsApiClient } from './Reports';
import { ServicesApiClient } from './Services';
import { TenantsApiClient } from './Tenants';
import { UsersApiClient } from './Users';

export const tdmsApiRef = createApiRef<TdmsClient>({
  id: 'plugin.tdms-next.api',
});

export class TDMSApiClient
  extends mixin(
    BookingsApiClient,
    EntitiesApiClient,
    PackagesApiClient,
    ReportsApiClient,
    ServicesApiClient,
    TenantsApiClient,
    UsersApiClient,
  )
  implements TdmsClient {}
