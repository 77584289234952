import { createApiRef } from '@backstage/core-plugin-api';
import {
  BookingServicePermissionType,
  BookingServicePropertyType,
  PlatformService,
  ServicePermission,
  ServicesApi,
} from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const servicesApiRef = createApiRef<ServicesApi>({
  id: 'plugin.tdms-next.services-api',
});

export class ServicesApiClient extends ApiClient implements ServicesApi {
  getBookingServicePermissions(
    acronym: string,
    serviceId: number,
  ): Promise<BookingServicePermissionType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/permissions/${serviceId}/${acronym}`,
      },
      actionPermissions.backOfficeServicePermissions.read,
    );
  }

  getBookingServiceProperties(
    serviceId: number,
  ): Promise<BookingServicePropertyType[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/properties/service/${serviceId}`,
      },
      actionPermissions.backOfficeServiceProperties.read,
    );
  }

  addBookingServiceProperty(
    serviceId: number,
    body: {
      name: string;
      type: string;
      required: boolean;
      availableValues: string | string[];
      defaultValue: string;
    },
  ): Promise<BookingServicePropertyType> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/properties/${serviceId}`,
        body,
      },
      actionPermissions.backOfficeServiceProperties.create,
    );
  }

  updateBookingServiceProperty(
    propertyId: number,
    body: {
      name: string;
      type: string;
      required: boolean;
      availableValues: string | string[];
      defaultValue: string;
    },
  ): Promise<BookingServicePropertyType> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/properties/${propertyId}`,
        body,
      },
      actionPermissions.backOfficeServiceProperties.update,
    );
  }

  removeBookingServiceProperty(propertyId: number): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/properties/${propertyId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficeServiceProperties.delete,
    );
  }

  setTenantBookingServicePermissions(
    acronym: string,
    body: {
      bookingId: number;
      servicePermissionId: number;
      userDaimlerIds: string[];
      elevateToServicePermissionId?: number;
    },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/platformBookingPermissions/${acronym}/batch`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.create,
    );
  }

  setTenantBookingServicePermission(
    acronym: string,
    body: {
      bookingId: number;
      servicePermissionId: number;
      userDaimlerId: string;
      elevateToServicePermissionId?: number;
    },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/platformBookingPermissions/${acronym}`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.update,
    );
  }

  updateTenantBookingServicePermission(
    acronym: string,
    body: { bookingPermissionIds: number[]; servicePermissionSlug: string },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/platformBookingPermissions/${acronym}`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.update,
    );
  }

  deleteTenantBookingServicePermission(
    acronym: string,
    bookingServicePermissionId: number,
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/platformBookingPermissions/${acronym}/${bookingServicePermissionId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficeServicePermissions.delete,
    );
  }

  getServices(): Promise<PlatformService[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/services`,
      },
      actionPermissions.backOfficeServices.read,
    );
  }

  addService(body: {
    name: string;
    provider: string;
    description: string;
  }): Promise<PlatformService> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/services`,
        body,
      },
      actionPermissions.backOfficeServices.create,
    );
  }

  updateService(
    serviceId: number,
    body: { name: string; provider: string; description: string },
  ): Promise<PlatformService> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/services/${serviceId}`,
        body,
      },
      actionPermissions.backOfficeServices.update,
    );
  }

  getServicePermissions(serviceId: string): Promise<ServicePermission[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/permissions/${serviceId}`,
      },
      actionPermissions.backOfficeServicePermissions.read,
    );
  }

  addServicePermission(
    serviceId: number,
    body: {
      name: string;
      description: string;
      nebulaOnly: boolean;
    },
  ): Promise<ServicePermission> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/permissions/${serviceId}`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.create,
    );
  }

  updateServicePermission(
    servicePermissionId: number,
    body: { name: string; description: string; nebulaOnly: boolean },
  ): Promise<ServicePermission> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/permissions/${servicePermissionId}`,
        body,
      },
      actionPermissions.backOfficeServicePermissions.update,
    );
  }

  removeServicePermission(servicePermissionId: number): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/permissions/${servicePermissionId}`,
        responseAsJson: false,
      },
      actionPermissions.backOfficeServicePermissions.delete,
    );
  }
}
