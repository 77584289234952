import {
  SettingsFilter,
  SettingsRequestOptions,
  UserSetting,
  UserSettingData,
} from './types';

export const settingEndpoint = (bucket: string, key: string) =>
  `/buckets/${encodeURIComponent(bucket)}/keys/${encodeURIComponent(key)}`;

/**
 * Store definition for the user settings.
 */
export interface UserSettingsApi {
  get(
    filter: SettingsFilter,
    options?: SettingsRequestOptions,
  ): Promise<UserSetting | undefined>;

  getAll(
    filter: SettingsFilter,
    options?: SettingsRequestOptions,
  ): Promise<UserSetting[] | undefined>;

  set(
    data: UserSettingData,
    options?: SettingsRequestOptions,
  ): Promise<UserSetting | undefined>;

  delete(
    filter: SettingsFilter,
    options?: SettingsRequestOptions,
  ): Promise<void>;
}
