import { createApiRef } from '@backstage/core-plugin-api';
import { Member, Tenant, TenantsApi, TenantsObject } from '@mb.io/tdms-common';
import { actionPermissions } from '../permissions';
import { ApiClient } from './ApiClient';
import { API_VERSION } from './constants';

export const tenantsApiRef = createApiRef<TenantsApi>({
  id: 'plugin.tdms-next.tenants-api',
});

export class TenantsApiClient extends ApiClient implements TenantsApi {
  getTenants(): Promise<Tenant[]> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/tenants`,
      },
      actionPermissions.tenants.read,
    );
  }

  createTenant(tenantData: Tenant): Promise<Tenant> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/tenants`,
        body: tenantData,
      },
      actionPermissions.tenant.create,
    );
  }

  deleteTenant(acronym: string): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/tenants/${acronym}`,
        responseAsJson: false,
      },
      actionPermissions.tenant.delete,
    );
  }

  getTenantsObject(): Promise<TenantsObject> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/tenants?type=object`,
      },
      actionPermissions.tenants.read,
    );
  }

  getTenant(acronym: string): Promise<Tenant> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/tenants/${acronym}`,
      },
      actionPermissions.tenant.read,
    );
  }

  updateTenant(acronym: string, tenantData: Tenant): Promise<Tenant> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/tenants/${acronym}`,
        body: tenantData,
      },
      actionPermissions.tenant.update,
    );
  }

  createTenantMember(acronym: string, memberData: Member): Promise<Member> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/tenants/${acronym}/members`,
        body: memberData,
      },
      actionPermissions.tenantMembers.create,
    );
  }

  updateTenantMember(acronym: string, memberData: Member): Promise<Member> {
    const { daimlerId } = memberData;
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/tenants/${acronym}/members/${daimlerId}`,
        body: memberData,
        responseAsJson: false,
      },
      actionPermissions.tenantMembers.update,
    );
  }

  deleteTenantMember(acronym: string, daimlerId: string): Promise<Member> {
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/tenants/${acronym}/members/${daimlerId}`,
        responseAsJson: false,
      },
      actionPermissions.tenantMembers.delete,
    );
  }

  getTenantMembers(acronym: string): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/tenants/${acronym}/members`,
      },
      actionPermissions.tenantMembers.read,
    );
  }

  setTenantMemberExpiryDate(
    acronym: string,
    body: { tenantUserRoleExpiryDate: string; daimlerIds: string[] },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/tenants/${acronym}/members/setUsersExpiryDate`,
        body,
      },
      actionPermissions.tenantMembers.update,
    );
  }

  batchCreateTenantMembers(
    acronym: string,
    body: {
      daimlerIds: string[];
      roles: string[];
      tenantUserRoleExpiryDate: string;
    },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'POST',
        path: `/${API_VERSION}/tenants/${acronym}/members/batch`,
        body,
      },
      actionPermissions.tenantMembers.create,
    );
  }

  batchUpdateTenantMembers(
    acronym: string,
    body: {
      daimlerIds: string[];
      roles: string[];
      tenantUserRoleExpiryDate?: string;
    },
  ): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'PUT',
        path: `/${API_VERSION}/tenants/${acronym}/members/batch`,
        body,
      },
      actionPermissions.tenantMembers.update,
    );
  }

  batchDeleteTenantMembers(
    acronym: string,
    daimlerIds: string[] | string,
  ): Promise<unknown> {
    const ids = Array.isArray(daimlerIds) ? daimlerIds.join(',') : daimlerIds;
    return this.submitRequest(
      {
        method: 'DELETE',
        path: `/${API_VERSION}/tenants/${acronym}/members/batch?daimlerIds=${ids}`,
        responseAsJson: false,
      },
      actionPermissions.tenantMembers.delete,
    );
  }

  getTenantMembersWithPermissions(acronym: string): Promise<unknown> {
    return this.submitRequest(
      {
        method: 'GET',
        path: `/${API_VERSION}/tenants/${acronym}/members/bookingPermissions/details`,
      },
      actionPermissions.tenantMembers.read,
    );
  }
}
